<template>
	<w-dialog v-if="value" :closable="false" max-width="500px" :title="$t('documents.errors.add_document_failed')" :value="dialog">
		<w-flex>
			{{ $t('documents.file_upload_error', { filename: value.name }) }}
		</w-flex>
		<template v-slot:actions>
			<w-spacer />
			<w-btn flat @click="doOK()">Ok</w-btn>
		</template>
	</w-dialog>
</template>
<script>
export default {
	name: 'ChecksumFailedDialog',
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			dialog: false
		}
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		doOK: function () {
			this.$emit('input', false)
		}
	}
}
</script>
